<template>
  <div>
    <el-card style="max-height: 500px;overflow-y: scroll">

      <h3 class="text">报名日志：</h3>
      <br>
      <el-timeline class="opt-log">
        <el-timeline-item
            v-for="(activity, index) in divideLog"
            :key="index"
            :color="activity.color"
        >
          {{ activity.created_at }}
          &nbsp; &nbsp; {{ activity.msg }}
        </el-timeline-item>
      </el-timeline>


    </el-card>
  </div>
</template>

<script>
export default {
  name: 'log',
  data() {
    return {
      divideLog: []
    }
  },
  props: {
    projectId: {
      type: [Number, String]
    },
    refresh: {
      type: Boolean
    }
  },
  methods: {
    async getlogsInquiry() {
      this.divideLog = await this.$api.logsApply({project_id: this.projectId})
    }
  },
  mounted() {
    this.getlogsInquiry()
    this.projectId = this.$route.params.id

  }
}
</script>


<style lang="scss" scoped>

.text {
  font-size: 20px;
  background: -webkit-linear-gradient(-90deg, #e97ca1 30%, #ff3176);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
