<template>
  <div>
    <div class="default-table">
      <el-table ref="multipleTable"
                :data="kolData"
                border
                stripe
                style="width: 100%">
        <el-table-column
            align="center"
            header-align="center"
            label="序号"
            type="index"
            width="80">
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="红人"
            min-width="120"
            prop="nicknames"
            :filters="[{text:'全部',value:0},{text:'在约',value:1}]"
            :filtered-value="[1]"
            :filter-method="filterHandler"
        >
          <template slot-scope="{row}">
            <el-tag v-if="row.is_assign" type="danger">定向</el-tag>
            {{ row.nicknames.text }}
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="合作意向"
            width="300"
        >
          <template slot-scope="{row}">
            <el-checkbox-group v-model="row.radio" :max="1" @change="selectChange">
              <el-checkbox :label="1">试用</el-checkbox>
              <el-checkbox :label="2">可接</el-checkbox>
              <el-checkbox v-if="row.is_assign" :label="3">拒接</el-checkbox>
            </el-checkbox-group>
            <el-input v-model="row.intention_note" :maxlength="50"
                      @blur="selectChange" v-if="row.radio.length>0&&row.radio!==3" placeholder="可给多个备选档期"
                      show-word-limit
            >
            </el-input>
            <el-input v-else-if="row.radio[0]===3" v-model="row.intention_note" placeholder="填写原因"
                      @blur="selectChange">
              填写拒接原因
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="平台"
            min-width="300">
          <template slot-scope="{row}">
            <el-checkbox-group v-model="row.checkList" @change="selectChange">
              <el-checkbox
                  v-for="(item,index) in infoPlatforms"
                  :key="index"
                  :disabled="!(row?.platforms[item.value]!=='N')||row.selectedPlatforms.indexOf(item.value)>-1"
                  :label="item.value">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column
            align="left"
            header-align="center"
            label="备注"
            width="300"
        >
          <template slot-scope="{row}">
            <el-input v-if="row.radio.length>0" v-model="row.note" :maxlength="200"
                      @blur="selectChange" type="textarea" autosize :placeholder="'请输入备注'" show-word-limit
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            label="报名结果"
            prop="status"
            width="120"
        >
          <template slot-scope="{row}">
            {{ row.status ? '已报' : '可报' }}
          </template>
        </el-table-column>
      </el-table>
      <br>
      <el-button
          :disabled="!(checkData?.length>0&&String(checkData[0]?.platforms)!=[])"
          :type="checkData?.length>0&&String(checkData[0]?.platforms)!=[]?'success':'info'"
          size="medium"
          @click="handleSuccess">
        预览数据
      </el-button>
    </div>
    <br>
    <apply-drawer ref="applyDrawer" :visible.sync="drawer" @closeDrawer="closeDrawer"
                  :infoStatus="infoStatus" :infoPlatforms="infoPlatforms" :checkData="checkData"></apply-drawer>

  </div>


</template>

<script>
import PriceGrids from '@/pages/publication/compoents/PriceGrids'
import {mapGetters} from 'vuex'
import PanelPlatformInfo from '@/pages/inquiries/components/PanelPlatformInfo'
import PanelKolInfo from '@/pages/inquiries/components/PanelKolInfo'
import PriceStructView from "@/pages/publication/compoents/PriceStructView.vue";
import PanelBsCase from "@/pages/publication/compoents/PanelBsCase.vue";
import ApplyDrawer from "@/pages/inquiries/apply/applyDrawer.vue";


export default {
  name: 'InfoDetail',
  components: {
    ApplyDrawer,
    PanelBsCase,
    PriceStructView,
    PanelKolInfo,
    PanelPlatformInfo,
    PriceGrids
  },
  data() {
    return {
      infoData: {},
      projectId: '',
      kolData: [],
      multipleSelection: [],
      drawer: false,
      checkData: [],
      tabPlatforms: [],
      combined: [],
      tableInfoData: [],
      dataList: [],
    }
  },
  props: {
    infoPlatforms: {
      type: Array
    },
    infoStatus: {
      type: Object
    },
  }, computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    // tabTranslate() {
    //   let newCheckList = []
    //  this.dataList.forEach((val) => {
    //     this.tabPlatforms.forEach((item) => {
    //       if (item == val.value) {
    //         newCheckList.push(val)
    //       }
    //     })
    //   })
    //   return newCheckList
    // }
  },
  methods: {
    selectChange() {
      let newKolData = []
      this.checkData = []
      this.kolData.forEach((item) => {
        (item.radio?.[0] ?? '') !== '' && item.checkList?.length > 0 ? newKolData.push(item) : ''
      })
      let checkData = {}
      if (newKolData?.length !== 0) {
        newKolData.forEach((item) => {
              item.selectedPlatforms.forEach((s) => {
                item.checkList = item.checkList.filter(i => i !== s)
              })
              // let reason = String(item.radio[0]) === '3' && item.radio[0] != undefined ? item.reason : ''
              checkData = {
                id: item.id,
                intention: item.radio[0],
                platforms: item.checkList,
                nickname: item.nickname,
                is_assign: item.is_assign,
                intention_note: item.intention_note,
                note: item.note
              }
              this.checkData.push(checkData)
            }
        )
      }
    },
    closeDrawer() {
      this.drawer = false
      this.tabPlatforms = []
      this.tableInfoData = []
      this.$emit('refresh')
    },
    async getInfo() {
      let data = await this.$api.InquiryKolList({ project_id: this.projectId })
      data.forEach((item) => {
        if (item['selectedPlatforms']?.length > 0) {
          item['checkList'] = item['selectedPlatforms']
        } else
          item['checkList'] = []
        item['kolPlatforms'] = []
        item['radio'] = []
        // item['nickname'].indices(' (解约)')
        if (!item['nickname'].includes(' (解约)')) {
          item['nicknames'] = { text: item.nickname, value: 1 }
        } else {
          item['nicknames'] = { text: item.nickname, value: 0 }
        }
      })
      data.forEach((item) => {
        let kolPlatforms = {}
        let newData = {}
        newData = item.platforms
        for (let i in newData) {
          if (newData[i] === 'Y') {
            kolPlatforms = i
            item.kolPlatforms.push(kolPlatforms)
          }
        }
      })
      this.kolData = data
    },

    async handleSuccess() {
      this.drawer = true
    },

    filterHandler(value, row) {
      if (value === 0) {
        return row.nickname
      } else
        return row.nicknames.value === value
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    this.getInfo()

  }
}
</script>
<style lang="scss">
.isAssign {
  font-size: 40px;
  display: inline-block;
  transform: rotate(45deg);
  z-index: 99;
  position: absolute;
  right: 0;
  top: 30px;
  background-color: #ffeaf1;
  background-size: 120%;
  color: #ff3176;

}
</style>
