<template>
  <el-drawer
      v-bind='$attrs' v-on='$listeners'
      :before-close="closeDrawer"
      @open="openDrawer"
      :size="'90%'"
      :with-header="false"
      title="报名详情">
    <div style="margin: 20px;">
      <el-tabs v-model="tabActive">
        <el-tab-pane v-for="(item,index) in tabTranslate" :key="index" :label="item.name"
                     :name="item.value">
          <el-table :data="dataList"
                    border stripe
                    style="width: 100%">
            <el-table-column
                label="序号"
                type="index"
                width="80">
            </el-table-column>
            <el-table-column label="合作意向" width="80">
              <template slot-scope="{row}">
                <span v-if="row.intention===1" style="color: blue">试用</span>
                <span v-if="row.intention===2" style="color: green">可接</span>
                <span v-if="row.intention===3" style="color: red">拒接</span>
              </template>
            </el-table-column>
            <el-table-column
                label="意向说明"
                width="120"
                prop="intention_note">
            </el-table-column>
            <el-table-column
                label="备注"
                width="150"
                prop="note">
            </el-table-column>
            <el-table-column align="center" header-align="center" label="基本信息"
                             width="300">
              <template slot-scope="{row,$index}">
                   <span class="isAssign"
                         v-if="row.is_assign===1">定向</span>
                <div v-if="row?.list&&row.list?.[tabActive]">

                  <PanelKolInfo :data='row.list[tabActive].plc_items[0]'
                                :platform-type='row.list[tabActive].plc_items[0].plc_type'
                                :setting='row.list[tabActive].plc_items[0].plc_type'
                                :index='$index' style='height: 100%;margin-bottom: 8px;'/>
                  <PanelPlatformInfo v-if="row.list[tabActive].plc_items[0]"
                                     :data="row.list[tabActive].plc_items[0]"/>
                </div>
                <span v-else>{{ row.nickname }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="当月价格" min-width="280"
                             prop="real_quote">
              <template slot-scope="{row}">
                <div v-if="row?.list?.[tabActive]?.plc_items">
                  <template v-for="(item,index) in row?.list?.[tabActive].plc_items">
                      <span v-if="row?.list?.[tabActive]?.plc_items" :key="index">
              <div v-if="item?.real_quote" class="price-div">
                    <div class="edit-box">
                  <PriceStructView :quote-obj='item?.real_quote' :platform-type="item.plc_type"/>
                    </div> <el-divider></el-divider>
              </div>
           </span></template>
                </div>
                <span v-else>暂无数据</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="商务案例" prop="bs_case">
              <template slot-scope="{row,$index}">
                <div v-if="row?.list?.[tabActive]?.plc_items">
                  <template v-for="(item,index) in row.list[tabActive].plc_items">
                      <span :key="index">
                            <panel-bs-case :row='item' :row-id='$index'></panel-bs-case>
                                                    <el-divider></el-divider>
                      </span>
                  </template>
                </div>
                <span v-else>暂无数据</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <br>
      <el-button size="medium" type="success" @click="handleEnroll">确定报名</el-button>

    </div>
  </el-drawer>

</template>

<script>
import PanelPlatformInfo from "@/pages/inquiries/components/PanelPlatformInfo.vue";
import PriceStructView from "@/pages/publication/compoents/PriceStructView.vue";
import PanelKolInfo from "@/pages/inquiries/components/PanelKolInfo.vue";
import PanelBsCase from "@/pages/publication/compoents/PanelBsCase.vue";

export default {
  name: 'applyDrawer',
  components: {PanelBsCase, PanelKolInfo, PriceStructView, PanelPlatformInfo},
  props: {
    checkData: {
      type: Array,
      default() {
        return []
      }
    },
    infoPlatforms: {
      type: Array,
      default() {
        return []
      }
    },
    infoStatus: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tabActive: '',
      tabTranslate: [],
      dataList: [],
      projectId: null

    }
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer')
    },
    openDrawer() {
      this.projectId = this.$route.params.id
      this.getTableData()
    },
    async getTableData() {
      let data = {}
      if (Number(this.infoStatus.coop_id) === 11052 || !this.infoStatus.coop_id) {
        data = {kols: this.checkData, is_qa: 'Y'}
      } else {
        data = {kols: this.checkData}
      }
      let list = await this.$api.preDataInquiryApply(data)
      //获取数据。和之前已有的数据进行合并
      this.dataList = list.map(item => {
        // 优化查找逻辑：将 checkData 转为对象，通过 ID 直接匹配
        const checkDataMap = Object.fromEntries(
            this.checkData.map(i => [i.id, i])
        );
        const matchedData = checkDataMap[item.artist_id];
        return matchedData ? {...item, ...matchedData} : item;
      });

      let platforms = [...new Set(this.checkData.flatMap(item => item.platforms))];

      const valueMap = this.infoPlatforms.reduce((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {});
      this.tabTranslate = platforms.map(platform => {
        return valueMap[platform];
      });
      this.tabActive = this.tabTranslate[0].value

    },
    async handleEnroll() {
      let kols = []
// 预处理 infoPlatforms 为 Map

      this.$confirm(`确认给${this.checkData.length}位红人报名?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const platformMap = new Map(this.infoPlatforms.map(val => [val.value, val.name]));

        this.dataList.forEach((item) => {
          // 初始化变量
          let newCheckList = [];
          let plc_item_ids = [];

          // 处理 platforms
          newCheckList = item.platforms
              .map(i => {
                const platform_name = platformMap.get(i);
                return platform_name ? {platform_code: i, platform_name} : null;
              })
              .filter(Boolean);

          // 处理 plc_item_id
          if (item.list) {
            plc_item_ids = Object.values(item.list || {}) // 关键修复点
                .flatMap(listItem =>
                    listItem.plc_items?.map(j => j.id) ?? []
                );
          }

          // 构建 enrollData
          const enrollData = {
            artist_id: item.id,
            intention: item.intention,
            platforms: newCheckList,
            is_assign: item.is_assign ? 1 : 2,
            nickname: item.nickname,
            intention_note: item.intention_note || '',
            note: item.note || '',
            plc_item_ids
          };

          // 推入 kols 数组
          kols.push(enrollData);
        });
        let id = await this.$api.InquiryEnroll({kols, 'project_id': this.projectId})
        if (id) {
          this.$notify.success('报名成功')
          this.closeDrawer()
        }
      }).catch(() => {
        this.$notify.info('已取消')
      })
    },

  }

}
</script>

<style lang="sass" scoped>

</style>